<template>
    <div>
        <v-container>
            <v-layout row wrap>
                <v-flex xs12 class="px-1">
                    Welcome to the dashboard
                    <h1>
                        {{currentTime}}
                    </h1>
                </v-flex>
                <v-flex xs12 lg3 xl3 md4>
                    <v-card class="ma-1" to="/customers-list">
                        <v-card-title primary-title>
                            Customers
                            <v-spacer></v-spacer>
                            <v-btn icon >
                                <v-icon>
                                    mdi-account-plus
                                </v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-card>
                </v-flex>
                <v-flex xs12 lg3 xl3 md4>
                    <v-card class="ma-1" to="/links-list">
                        <v-card-title primary-title>
                            Links
                            <v-spacer></v-spacer>
                            <v-btn icon >
                                <v-icon>
                                    mdi-link-plus
                                </v-icon>
                            </v-btn>
                        </v-card-title>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>
<script>
import moment from 'moment';
export default {
    data() {
        return {
            currentTime: moment().format('MMMM Do YYYY, h:mm:ss')
        }
    },
    mounted() {
        setInterval(() => {
            this.currentTime = moment().format('MMMM Do YYYY, h:mm:ss')
        }, 1000)
    },
}
</script>